@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');


.App {
  font-family: 'Roboto', sans-serif;
  text-align: center;
  background-color: #D4CDC3;
}

.Body{
  background-color: #D4CDC3;
  font-family: "Mingzat", sans-serif;
  font-weight: 400;
  font-style: normal;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.xxeder{
  text-align: center;
  padding: 10px 20px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
