.footer-container {
    margin-top: 10px;
    display: flex;
    width: 100%;
    background-color: #232124;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding-top: 20px;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 2;
    flex-direction: column;
}

.icon-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 40%;
}

.column-item {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 75%;
}

.column-icon {
    height: auto;
    width: 25%;
}

.whatsapp {
    background-color: #232124;
    border-radius: 10px;
    width: 75%;
}

.column-href {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    width: 100%;
    margin-bottom: 10px;
}

.icons{
    display: flex;
    margin-bottom: 20px;

}

.column-row {
    display: flex;
    justify-content: center;
    justify-items: center;
    align-items: center;
    align-content: center;
}

.icons-dev{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 75%;

}

.column-item-dev{
    justify-content: center;
    align-items: center;
    width: 50%;
}

.developer-row{
    width: 50%;
    display: flex;
    flex-direction: column;
    filter: blur(0.5px);
    font-size: 0.8em;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin: auto;
}

.naming{
    color: white;
    text-decoration: none;
    font-size: 0.8rem;
}

.copyright{
    font-size: 0.7em;
    margin-bottom: 10px;
}

@media (min-width: 1025px) {
    .footer-container {
        padding-top: 40px;
    }

    .icon-container {
        width: 60%;
    }

    .column-icon {
        width: 40%;
    }
}

/* Стили для планшетов */
@media (min-width: 768px) and (max-width: 1024px) {
    .footer-container {
        padding-top: 30px;
    }

    .icon-container {
        width: 55%;
    }

    .column-icon {
        width: 40%;
    }
}

/* Стили для мобильных устройств */
@media (max-width: 767px) {
    .footer-container {
        padding-top: 20px;
    }

    .naming{
        font-size: 0.7rem;
    }

    .icon-container {
        width: 80%;
    }

    .column-item {
        width: 90%;
        margin-top: 15px;
    }

    .column-icon {
        width: 50%;
    }
}
