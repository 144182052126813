.About-li {
    display: flex;
    flex-direction: column;
}

.About-li > * {
    margin-bottom: 7px; /* Добавляем отступ между элементами */
}

.About-page{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: center;
}

.about-link {
    cursor: pointer;
    text-decoration: none;
    width: 75%;
}

.about-button {
    padding: 10px 20px;
    background-color: #232124;
    color: white;
    border-radius: 20px;
    cursor: pointer;
    margin-bottom: 7px;
    text-align: center;
    font-size: 1.2rem;
}

.Buttons{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

@media screen and (max-width: 750px) {
    .About-li > * {
        margin-bottom: 5px; /* Добавляем отступ между элементами */
    }

    .about-button{
        border-radius: 15px;
    }

    .about-link{
        width: 90%;
    }
}

@media screen and (max-width: 500px) {
    .About-li > * {
        margin-bottom: 3px; /* Добавляем отступ между элементами */
    }

    .about-button{
        border-radius: 20px;
    }

    .about-link{
        width: 95%;
    }
}