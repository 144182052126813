.Header-container {
    background: url('../../ref/wave_for_header.svg') no-repeat center center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 95px;
    text-align: center;
}

.Header-name {
    color: white;
    font-size: 1.8em;
}

.hidden {
    display: none;
}

.Header-logo {
    max-width: 150px;
    margin: 20px;
    border-radius: 50%;
}

.Who-I-Am{
    color: white;
    font-size: 1.5em;
}

@media (max-width: 750px) {
    .Header-container{
        padding-bottom: 70px;
    }

    .Header-name{
        font-size: 1.5rem;
    }

    .Who-I-Am{
        font-size: 1.2em;
    }
}

@media (max-width: 550px) {
    .Header-container{
        padding-bottom: 85px;
    }
}