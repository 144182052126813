p {
    margin: 0;
}

h1 {
    color: #C5B4AC;
    cursor: pointer;
    position: relative;
    font-size: 2rem;
    margin: 0 10px;
}

.icon {
    display: inline-block;
    transition: transform 0.3s;
    transform-origin: center;
}

.open .icon {
    transform: rotate(360deg);
}

.open .icon.minus {
    transform: rotate(360deg);
}

h1.open {
    cursor: pointer;
}

.textt {
    margin: 0 15px;
    color: white;
    max-height: 0;
    overflow: hidden;
    transition: max-height 1s, margin 1s;
    word-wrap: break-word;
    text-align: justify-all;
    font-size: 1.6rem;
}

.textt.open {
    transition: max-height 1s, margin 1s;
    max-height: 100000000px;
    padding: 5px/* Установите значение, которое гарантированно больше высоты контента */
}

.AboutInfoTemplate {
    background-color: #232124;
    border-radius: 20px;
    width: 75%;
    margin: 0 auto 8px auto;

}

@media (max-width: 750px) {
    h1 {
        font-size: 1.5rem;
        margin: 0 8px;
    }

    .AboutInfoTemplate {
        border-radius: 15px;
    }

    .textt.open {
        font-size: 1.4rem;
        margin: 0 8px;
    }

    .AboutInfoTemplate {
        width: 90%;
    }
}

@media (max-width: 500px) {
    h1 {
        font-size: 2rem;
        margin: 0 6px;
    }

    .AboutInfoTemplate {
        border-radius: 20px;
    }

    .textt.open {
        font-size: 1.2rem;
        margin: 0 5px;
    }

    .AboutInfoTemplate {
        width: 95%;
    }
}
