body {
    margin: 0;
    padding: 0;
    background-color: #f0f0f0; /* Цвет фона как для блоков в AboutInfoTemplate */
    color: #f0f0f0; /* Цвет текста как в блоках в AboutInfoTemplate */
    font-family: Arial, sans-serif; /* Шрифт текста */
}

.error-container {
    background-color: #232124;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.error-title {
    font-size: 3rem;
    color: #f0f0f0;
}

.error-message {
    font-size: 1.5rem;
    margin-bottom: 20px;
}

.error-link {
    cursor: pointer;
    text-decoration: none;
}

.error-button {
    padding: 10px 20px;
    background-color: #232124;
    color: #f0f0f0;
    border-radius: 5px;
    cursor: pointer;
    border-color: #f0f0f0;
}
